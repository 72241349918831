import React from 'react';
import Div from '../Div';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import Newsletter from '../Widget/Newsletter';
import SocialWidget from '../Widget/SocialWidget';
import TextWidget from '../Widget/TextWidget';
import './footer.scss';
const copyrightLinks = [
  {
    title: 'Terms of Use',
    href: '/',
  },
  {
    title: 'Privacy Policy',
    href: '/',
  },
];

const serviceMenu = [
  {
    title: 'UI/UX design',
    href: '/service',
  },
  {
    title: 'Mobile App development',
    href: '/service',
  },
  {
    title: 'Web Development',
    href: '/service',
  },
  {
    title: 'Cloud Solutions',
    href: '/service',
  },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-6 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/Logo White Black.svg"
                  logoAlt="Logo"
                  text="ODDMAX is your partner in crafting innovative software solutions. We deliver customized, cutting-edge technology tailored to meet the unique demands of your business.Whether you're looking to streamline operations, enhance customer engagement, or innovate your offerings, ODDMAX delivers solutions that empower your business to thrive in a competitive market."


                />
                {/* <SocialWidget /> */}
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              {/* <Div className="cs-footer_item">
                <Newsletter
                  title="Subscribe"
                  subtitle="Stay ahead in the tech world with our exclusive content. Join our mailing list now!"
                  placeholder="example@gmail.com"
                />
              </Div> */}
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 Oddmax.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
